<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://i.pinimg.com/originals/1c/d8/47/1cd84761e613e417f08ea18f3586a884.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a
                class="title"
                title="Merchandising Material
"
                >Merchandising Material
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="col-md-10 offset-md-1">
        <div class="page-content">
          <div>
            <ul class="entry-list">
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a href="">Company Profile </a>
                </span>
              </li>
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a href="">Brochure </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchandisingMaterial"
};
</script>
